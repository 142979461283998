import React, { useEffect, useState } from "react";
import { BiHide } from "react-icons/bi";
import { IoDocumentTextOutline, IoEyeOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const ProductDescription = () => {
  const [descriptionData, setDescriptionData] = useState({
    features: [],
    overview: [],
  });

  const { "*": searchInput } = useParams();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const imageurl = process.env.REACT_APP_BASE;
  const [showCompatibility, setShowCompatibility] = useState(false);
  const [modificationsData, setModificationsData] = useState([]);

  const navigate = useNavigate();

  if (!apiUrl) {
    console.error(
      "REACT_APP_API_URL is not defined in the environment variables."
    );
  }

  const productUrl = apiUrl
    ? `${apiUrl}users/searchedproduct/${searchInput}`
    : null;

  const compatablityurl = apiUrl
    ? `${apiUrl}users/similarproducts/${searchInput}`
    : null;

  useEffect(() => {
    if (productUrl) {
      fetch(productUrl)
        .then((res) => res.json())
        .then((response) => {
          const data = response.data;
          if (Array.isArray(data)) {
            // Flatten the array of arrays into a single array of products
            const flattenedData = data.reduce(
              (acc, productsArray) => acc.concat(productsArray),
              []
            );
            console.log("Flattened data:", flattenedData);
            const product = flattenedData[0];
            const feature = [product.part_id.description];
            const transformedAttributes = Array.isArray(
              product.part_id.attributes
            )
              ? product.part_id.attributes.map((attribute) => ({
                  label: attribute.attributeName,
                  value: attribute.attributeValue,
                }))
              : [];

            const overview = [
              {
                label: "Vechile Company ",
                value: product.part_id.vehicleCompany,
              },
              { label: "Brand", value: product.part_id.brand },
              { label: "Model", value: product.part_id.model },
              { label: "Part Name", value: product.part_id.partName },
              { label: "Quantity", value: product.stockQuantity },
              ...transformedAttributes,
            ];

            setDescriptionData(() => ({
              features: feature,
              overview: overview,
            }));

            console.log("Transformed data:", descriptionData);
          } else {
            console.error("API response is not an array:", data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [searchInput, productUrl]);

  const handleViewCompatibility = () => {
    setShowCompatibility(!showCompatibility);
    fetch(compatablityurl)
      .then((res) => res.json())
      .then((response) => {
        const data = response.data;
        const transformationdata = data.map((part) => ({
          id: part.id,
          vechileCompany: part.vehicleCompany,
          subcategory: part.subCategory,
          model: part.model,
          manufactureYear: part.manufactureYear,
        }));
        const uniqueData = transformationdata.reduce((acc, current) => {
          const x = acc.find(
            (item) =>
              item.vechileCompany === current.vechileCompany &&
              item.subcategory === current.subcategory &&
              item.model === current.model &&
              item.manufactureYear === current.manufactureYear
          );
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        setModificationsData(uniqueData);
      })
      .catch((error) => {
        console.error("Error fetching compatibility data:", error);
      });
  };

  return (
    <div className="bg-white">
      <div className="bg-white shadow-md px-6 md:px-12 flex items-center justify-between">
        <div className="w-full flex justify-between items-center gap-6 md:gap-12">
          <button className="text-blue flex items-center gap-1 font-bold border-b-[3px] border-blue py-2">
            <IoDocumentTextOutline className="text-xl" />
            Description
          </button>

          <button
            className="text-blue flex items-center gap-1 font-bold py-2 border-b-[3px] transition-all duration-300 border-[transparent] hover:border-blue"
            onClick={handleViewCompatibility}
          >
            {showCompatibility ? (
              <>
                <BiHide className="text-xl" />
                <span>Hide Compatibility</span>
              </>
            ) : (
              <>
                <IoEyeOutline className="text-xl" />
                <span>View Compatibility</span>
              </>
            )}
          </button>
        </div>
      </div>

      <div className="py-8">
        <div className="px-6 md:px-12 flex flex-col gap-4 md:gap-6 font-medium">
          <p>
            {descriptionData.features.map((feature, index) => (
              <span key={index}>{feature}</span>
            ))}
          </p>
        </div>

        <div className="bg-gray-300 w-full h-[2px] my-6 md:my-8"></div>

        <div className="px-6 md:px-12 flex flex-col gap-6">
          <h1 className="font-bold text-blue">Overview</h1>

          <div className="flex flex-col md:flex-row items-center gap-3 md:gap-6">
            <ul className="md:w-[50%] flex flex-col gap-3">
              {descriptionData.overview.map((item, index) => (
                <li
                  key={index}
                  className="font-bold text-gray-800 flex items-center justify-between gap-14"
                >
                  <span className="w-[120px]">{item.label}</span>
                  <span className="flex-1 font-medium text-gray-700 text-[15px]">
                    {item.value}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {showCompatibility && (
        <>
          <div className="bg-gray-300 w-full h-[2px] mb-6"></div>

          <div className="overflow-x-auto whitespace-nowrap table-container flex flex-col gap-4">
            <h1 className="font-bold text-blue px-6 md:px-12">Compatibility</h1>

            <table className="w-full text-white text-center border-spacing-4">
              <thead className="text-gray-800 bg-[#ECF9FF] uppercase">
                <tr>
                  <th className="text-center">Vehicle Company</th>
                  <th className="text-center">Sub Category</th>
                  <th className="text-center">Model</th>
                  <th className="text-center">Manufacture Year</th>
                </tr>
              </thead>

              <tbody className="text-gray-700">
                {modificationsData.length === 0 ? (
                  <tr>
                    <td colSpan="5" className="text-center font-medium py-4">
                      No similar product found
                    </td>
                  </tr>
                ) : (
                  modificationsData.map((modification, index) => (
                    <tr
                      key={index}
                      className="border-b-2 border-gray-300 text-gray-700 relative hover:bg-gray-100"
                    >
                      <td className="text-center">
                        {modification.vechileCompany}
                      </td>
                      <td className="text-center">
                        {modification.subcategory}
                      </td>
                      <td className="text-center">{modification.model}</td>
                      <td className="text-center">
                        {modification.manufactureYear}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductDescription;
