import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { IoLocation } from "react-icons/io5";
import InputWithLabel from "../../common/InputWithLabel";
import PhoneInputWithLabel from "../../common/PhoneInputWithLabel";
import { currentStepAtom } from "./STepIndicator3";
import axios from "axios";
import AddressCard from "../common/AddressCard";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const ShippingAddressForm = ({
  formData,
  setFormData,
  errors,
  setErrors,
  location,
}) => {
  const [, setCurrentStep] = useAtom(currentStepAtom);
  const [addressId, setAddressId] = useState(null);
  const [address, setAddress] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Clear the error for the changed field
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validateForm = () => {
    const { fullname, phone, Address, PAN } = formData;
    const newErrors = {
      fullname: fullname ? "" : "Please enter the billing name.",
      phone: phone
        ? isValidPhone(phone)
          ? ""
          : "Please enter a valid phone number."
        : "Please enter the phone number.",
      Address: Address ? "" : "Please enter the Address.",
      PAN: PAN
        ? isValidPAN(PAN)
          ? ""
          : "Please enter the 9 digit PAN Number"
        : "",
    };
    return newErrors;
  };

  const isValidPhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const isValidPAN = (PAN) => {
    if (!(PAN.trim() === "" || /^\d{9}$/.test(PAN.trim()))) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const token = localStorage.getItem("usertoken");
    try {
      const url = process.env.REACT_APP_BASE_URL + `users/getaddresses/`;
      const response = await axios.get(`${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        console.log("Fetched data:", response.data.data); // Log fetched data
        setAddress(response.data.data); // Replace the current address state
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleAddressCardClick = (id) => {
    const selectedAddress = address.find((address) => address.id === id);
    if (selectedAddress) {
      setAddressId(id);
      setFormData((prevData) => ({
        ...prevData,
        Address: id,
      }));
      setCurrentStep((prevStep) => Math.min(prevStep + 1, 3));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = process.env.REACT_APP_BASE_URL + "users/address/";
    const token = localStorage.getItem("usertoken");

    try {
      const response = await axios.post(
        url,
        {
          fullName: formData.fullname,
          phoneNum: formData.phone,
          fullAddress: formData.Address,
          label: "Shipping",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Set content type to JSON
          },
        }
      );

      if (response.status === 201) {
        console.log(response.data.message); // Log the success message
        const addressId = response.data.data.id; // Extract the address ID from the response
        setAddressId(addressId); // Save the ID of the added address
        // Update the address data with the new ID
        setFormData((prevData) => ({
          ...prevData,
          Address: addressId,
        }));
      }

      const newErrors = validateForm();

      if (Object.values(newErrors).some((error) => error !== "")) {
        setErrors(newErrors);
        return;
      }

      // Move to the next step
      setCurrentStep((prevStep) => Math.min(prevStep + 1, 3));
    } catch (error) {
      console.error("Error adding address:", error);
      // Handle error here (e.g., set error state)
    }
  };

  return (
    <>
      <div className="flex text-lg items-center gap-2 px-6 py-6 text-blue-800 font-bold">
        <IoLocation className="min-w-[20px] min-h-[20px]" />
        <p>Select the Required Address</p>
      </div>
      <div className="flex justify-between items-center max-w-[80%] 2xl:mx-auto mx-[5%] ">
        {address.map((addres) => (
          <div key={addres.id} className="slider-item">
            <AddressCard
              name={addres.fullname}
              phone={addres.phone}
              label={addres.label}
              address={addres.descriptiveaddress}
              id={addres.id}
              onClick={handleAddressCardClick}
            />
          </div>
        ))}
      </div>

      <div className="flex-1 bg-white py-6 px-6 sm:px-10 flex flex-col gap-6">
        <div className="flex items-center justify-between">
          <div className="flex text-lg items-center gap-2 text-blue-800 font-bold">
            <IoLocation className="min-w-[20px] min-h-[20px]" />
            <p>Enter a new Billing Address</p>
          </div>
        </div>

        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-10"
          encType="multipart/form-data"
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <InputWithLabel
                htmlFor="fullname"
                label="Billing Name"
                type="text"
                placeholder="Billing Name"
                name="fullname"
                value={formData.fullname}
                onChange={handleInputChange}
              />

              {errors.fullname && (
                <p className="text-red-900 text-[14px] font-medium">
                  {errors.fullname}
                </p>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <PhoneInputWithLabel
                label="Phone Number"
                name="phone"
                placeholder="9152545652"
                value={formData.phone}
                onChange={handleInputChange}
              />

              {errors.phone && (
                <p className="text-red-900 text-[14px] font-medium">
                  {errors.phone}
                </p>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <InputWithLabel
                htmlFor="Address"
                label="Address"
                type="text"
                placeholder="Street Address or PO Box"
                name="Address"
                value={formData.Address}
                onChange={handleInputChange}
              />

              {errors.Address && (
                <p className="text-red-900 text-[14px] font-medium">
                  {errors.Address}
                </p>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <InputWithLabel
                htmlFor="PAN"
                label="PAN Number"
                type="text"
                placeholder="PAN Number"
                name="PAN"
                value={formData.PAN}
                onChange={handleInputChange}
              />

              {errors.PAN && (
                <p className="text-red-900 text-[14px] font-medium">
                  {errors.PAN}
                </p>
              )}
            </div>
          </div>

          <div className="flex lg:justify-end">
            <button
              type="submit"
              className="w-full lg:w-fit text-[17px] bg-blue px-8 py-2 rounded-md text-white font-semibold"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ShippingAddressForm;
