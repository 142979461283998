import axios from "axios";
import { useAtom } from "jotai";
import React, { useEffect, useRef, useState } from "react";
import { HiChevronDown, HiChevronLeft, HiChevronRight } from "react-icons/hi";
import AllProductsTable, {
  dataAtom,
  rowsPerPageAtom,
} from "./AllProductsTable";

const AllProducts = ({
  searchQuery,
  isAnyRowSelected,
  setIsAnyRowSelected,
  selectedRows,
  setSelectedRows,
}) => {
  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageAtom);
  const [data, setData] = useAtom(dataAtom);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false); // Added loading state

  const optionsRef = useRef(null);
  const [showRowsPerPageOptions, setShowRowsPerPageOptions] = useState(false);
  const rowsPerPageOptions = [10, 12, 14, 16, 18, 20, 50, 100];

  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const url = `${process.env.REACT_APP_BASE_URL}admin/approved-partinfo/`;
      const response = await axios.get(url, {
        params: {
          page: currentPage,
          limit: rowsPerPage,
        },
      });
      const apiData = response.data.results;
      const totalItems = response.data.count;

      setTotalItems(totalItems);

      const transformedData = apiData.map((item) => ({
        productId: item.id,
        status: item.status,
        vehicleCompany: item.vehicleCompany,
        subCategory: item.subCategory,
        availability: item.availability,
        brand: item.brand,
        model: item.model,
        manufactureYear: item.manufactureYear,
        partCategories: item.partCategories,
        partSubCategories: item.partSubCategories,
        partName: item.partName,
        partNumber: Array.isArray(item.partNumber)
          ? item.partNumber.map((part) => part.partNumber).join(", ")
          : item.partNumber,
        description: item.description,
        coverPhoto: item.image ? item.image : null,
        otherAttributes: Array.isArray(item.attributes)
          ? item.attributes.map((attribute) => ({
              attributeName: attribute.attributeName,
              attributeValue: attribute.attributeValue,
            }))
          : [],
      }));

      setData(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, rowsPerPage]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        showRowsPerPageOptions &&
        optionsRef.current &&
        !optionsRef.current.contains(event.target)
      ) {
        setShowRowsPerPageOptions(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showRowsPerPageOptions]);

  const totalPages = Math.ceil(totalItems / rowsPerPage);

  const handleLeftArrowClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRightArrowClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const startIndex = (currentPage - 1) * rowsPerPage + 1;
  const endIndex = Math.min(startIndex + rowsPerPage - 1, totalItems);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center h-48">
          {" "}
          <p>Loading...</p>
        </div>
      ) : (
        <>
          <AllProductsTable
            startIndex={startIndex}
            endIndex={endIndex}
            searchQuery={searchQuery}
            fetchData={fetchData}
            isAnyRowSelected={isAnyRowSelected}
            setIsAnyRowSelected={setIsAnyRowSelected}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />

          <div className="flex items-center justify-center text-gray-500 sm:justify-between">
            <div className="hidden sm:block">
              <p className="font-medium text-[15px]">
                {startIndex}-{endIndex} of <span>{totalItems}</span>
              </p>
            </div>

            <div className="text-[15px] font-medium flex items-center gap-6">
              <div className="items-center gap-2 hidden sm:flex">
                <p>Rows per page:</p>

                <div
                  className="relative flex items-center gap-1 cursor-pointer"
                  onClick={() =>
                    setShowRowsPerPageOptions(!showRowsPerPageOptions)
                  }
                >
                  <p>{rowsPerPage}</p>
                  <HiChevronDown className="text-[20px]" />

                  {showRowsPerPageOptions && (
                    <div
                      ref={optionsRef}
                      className="absolute right-6 bg-white border border-gray-300 mt-2 p-2 rounded-md"
                    >
                      {rowsPerPageOptions.map((option) => (
                        <div
                          key={option}
                          className="cursor-pointer hover:bg-gray-200 p-1"
                          onClick={() => {
                            setRowsPerPage(option);
                            setShowRowsPerPageOptions(false);
                          }}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-center gap-3">
                <button
                  className="border-2 border-gray-300 text-gray-700 disabled:text-[#A1A9B8] rounded-xl p-1"
                  onClick={handleLeftArrowClick}
                  disabled={currentPage === 1}
                >
                  <HiChevronLeft className="text-[24px]" />
                </button>

                <div>
                  {currentPage}/{totalPages}
                </div>

                <button
                  className="border-2 border-gray-300 text-gray-700 disabled:text-[#A1A9B8] rounded-xl p-1"
                  onClick={handleRightArrowClick}
                  disabled={currentPage === totalPages}
                >
                  <HiChevronRight className="text-[24px]" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AllProducts;
