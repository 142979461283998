import React, { useEffect, useState } from "react";
import ProductCard from "../common/ProductCard";

const MoreFeaturedProducts = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const featuredProductUrl =
        process.env.REACT_APP_BASE_URL + "homepage/latestproduct/";
      try {
        const response = await fetch(featuredProductUrl);
        const data = await response.json();

        const transformedData = data.map((product) => ({
          id: product.id,
          imageUrl: product.part_id.image,
          brand: product.part_id.brand,
          title: product.part_id.partName,
          price: product.normalRate,
          unit: product.units,
          status: product.status,
          sellerId: product.seller_id,
        }));

        setProducts(transformedData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="bg-gray-200 px-6 md:px-10 lg:px-20 pt-5 md:pt-10 pb-12">
      <div className="flex items-center justify-between">
        <h1 className="text-lg vsm:text-xl font-bold">Latest Products</h1>
      </div>

      <div className="relative w-full mt-2 mb-5 md:mb-10 bg-gray-300 h-[2px] flex">
        <div className="absolute top-0 left-0 flex">
          <div className="w-[160px] bg-yellow h-[10px]"></div>
          <div className="w-[50px] relative -left-4 bg-yellow h-[10px] skew-x-[-50deg]"></div>
        </div>
      </div>

      <div className="grid vsm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 pt-6 gap-6 sm:gap-8">
        {products.map((product) => (
          <ProductCard
            key={product.id}
            imageUrl={product.imageUrl}
            brand={product.brand}
            title={product.title}
            price={product.price}
            unit={product.unit}
            id={product.id}
          />
        ))}
      </div>
    </div>
  );
};

export default MoreFeaturedProducts;
