import axios from "axios";
import { atom, useAtom } from "jotai";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { BiTrashAlt } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify
import Footer from "../common/Footer";
import Header from "../common/Header";
import {
  buynowValue,
  indivisualprice,
  indproductDetails,
} from "../ProductDetail/ProductDetailsTop";
import { useNavigate } from "react-router-dom";

export const totalItemValue = atom(0);
export const totalPriceValue = atom(0);
export const cartId = atom(0);

const ShoppingCart = () => {
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  const [totalItems, setTotalItems] = useAtom(totalItemValue);
  const [totalPrices, setTotalPrices] = useAtom(totalPriceValue);
  const [indivisualPrice, setIndivisualPrice] = useAtom(indivisualprice);
  const [productDetails, setProductDetails] = useAtom(indproductDetails);
  const [cartID, setCartID] = useAtom(cartId);
  const [buynow, setbuyNow] = useAtom(buynowValue);
  const [selectAll, setSelectAll] = useState(false);

  // const [quantity, setQuantity] = useState(0);

  const imageUrl = process.env.REACT_APP_BASE;
  const url = process.env.REACT_APP_BASE_URL + "users/cart/";
  const fetchData = async () => {
    const token = localStorage.getItem("usertoken");
    if (!token) {
      // Handle the case where the token is not present (e.g., redirect to login page)
      toast.error("Authentication credentials not provided. Please log in.");
      setTimeout(() => {
        navigate("/login");
      }, 3000);
      return;
    }

    // Check if the authentication token has expired (example for JWT, may vary based on implementation)
    const isTokenExpired = () => {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        return decodedToken.exp < currentTime;
      } catch (error) {
        return true; // Error decoding token or invalid token, treat as expired
      }
    };

    if (isTokenExpired()) {
      // Handle token expiration (e.g., refresh the token or redirect to login page)
      toast.error("Authentication token has expired. Please log in again.");
      navigate("/login");
      return;
    }
    try {
      const response = await axios.get(`${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const apiData = response.data.data;

      console.log("API Data", apiData);

      const transformedData = apiData.map((item) => {
        const findprice =
          item.quantity > 5 && item.product.bulkRate
            ? item.product.bulkRate
            : item.product.normalRate;

        const findtotalPrice = findprice * item.quantity;

        return {
          id: item.id,
          productid: item.product.id,
          imageUrl: item.product.part_id.image,
          title: item.product.part_id.partName,
          brand: item.product.part_id.brand,
          singleprice: item.product.normalRate,

          totalItems: item.quantity,
          totalPrices: findtotalPrice,
        };
      });

      setProductData(transformedData);
      setCartID(apiData[0].cart.id);
      handlerestore(apiData[0].cart.id);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!buynow) {
      fetchData();
    }
    setTimeout(() => {
      if (buynow) {
        const str = indivisualPrice;
        const num = parseInt(str.replace(/[^0-9.-]+/g, ""));
        console.log("num", num);

        const productDetailsdata = productDetails;

        const productDetail = parseInt(productDetailsdata);

        setTotalPrices(num);
        const updateCartID = async () => {
          await setCartID(productDetail);
        };

        updateCartID();
        console.log("productDetails", productDetail);
        console.log("cartID", cartID);

        setTotalItems(1);
        setbuyNow(false);

        navigate("/place-order");
      }
    }, 300);
  }, [buynow, navigate]);

  const calculateTotalPrice = (apiData) => {
    let totalPrice = 0;

    apiData.forEach((item) => {
      const { bulkRate, normalRate } = item.product;
      const { quantity } = item;

      if (quantity > 4 && bulkRate) {
        totalPrice += bulkRate * quantity;
      } else {
        totalPrice += normalRate * quantity;
      }
    });

    return totalPrice;
  };

  const calculateTotalQuantity = (apiData) => {
    let totalquantity = 0;

    apiData.forEach((item) => {
      const { quantity } = item;
      totalquantity += quantity;
    });

    return totalquantity;
  };

  const handleIncrement = async (id, qty) => {
    setSelectAll(false);
    setTotalPrices(0);
    setTotalItems(0);

    const quantity = qty + 1;
    const updateurl =
      process.env.REACT_APP_BASE_URL +
      `users/update_quantity/${id}/${quantity}/`;
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(updateurl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      toast.success("Quantity Added");
      fetchData();
    }
  };

  const handlerestore = async (cart) => {
    const token = localStorage.getItem("usertoken");
    const updateRestoreURL =
      process.env.REACT_APP_BASE_URL + "users/restore_cart_items/";
    const cartId = cart;
    try {
      await axios.post(
        updateRestoreURL,
        { cart_id: cartId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      toast.error("Failed to restore cart items");
      // Handle error if needed
    }
  };

  const handleDecrement = async (id, qty) => {
    setSelectAll(false);
    setTotalPrices(0);
    setTotalItems(0);
    if (qty === 1) {
      handleRemove(id);
    }
    const quantity = qty - 1;
    const updateurl =
      process.env.REACT_APP_BASE_URL +
      `users/update_quantity/${id}/${quantity}/`;
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(updateurl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      toast.error("Quantity Removed");
      fetchData();
    }
  };

  const handleRemove = async (id) => {
    const removeurl =
      process.env.REACT_APP_BASE_URL + `users/remove_from_cart/${id}/`;
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(removeurl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      toast.success("Success", "Product Removed");
      fetchData();
    }
  };

  const removeAll = async () => {
    const removeall =
      process.env.REACT_APP_BASE_URL + `users/remove_all_from_cart/`;
    const token = localStorage.getItem("usertoken");
    const response = await axios.get(removeall, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      toast.success("Success", "Product Removed");
      fetchData();
    }
  };

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    const updatedProductData = productData.map((product) => ({
      ...product,
      selected: isChecked,
    }));
    setProductData(updatedProductData);

    // Recalculate total items and total prices based on the selected products
    const selectedProducts = isChecked ? updatedProductData : [];
    const newTotalItems = selectedProducts.reduce(
      (total, product) => total + product.totalItems,
      0
    );
    const newTotalPrices = selectedProducts.reduce(
      (total, product) => total + product.totalPrices,
      0
    );
    setTotalItems(newTotalItems);
    setTotalPrices(newTotalPrices);
  };

  const handleCheckboxChange = (e, productId) => {
    const updatedProductData = productData.map((product) => {
      if (product.id === productId) {
        return { ...product, selected: e.target.checked };
      }
      return product;
    });
    setProductData(updatedProductData);
    setSelectAll(updatedProductData.every((product) => product.selected));

    // Recalculate total items and total prices
    const selectedProducts = updatedProductData.filter(
      (product) => product.selected
    );
    const newTotalItems = selectedProducts.reduce(
      (total, product) => total + product.totalItems,
      0
    );
    const newTotalPrices = selectedProducts.reduce(
      (total, product) => total + product.totalPrices,
      0
    );
    setTotalItems(newTotalItems);
    setTotalPrices(newTotalPrices);
  };

  const handleCheckOut = async () => {
    // Filter out only the selected items for checkout
    console.log("productData", productData);
    const selectedItems = productData.filter((product) => product.selected);
    if (selectedItems.length === 0) {
      // If no items are selected, show a toast message
      toast.error("Please select at least one item for checkout.");
      return;
    }

    const token = localStorage.getItem("usertoken");
    const updateInactiveURL =
      process.env.REACT_APP_BASE_URL + "users/update_cart_items/";
    const activeItemIds = selectedItems.map((item) => item.id); // Corrected variable name

    try {
      await axios.post(
        updateInactiveURL,
        { item_ids: activeItemIds },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTimeout(() => {
        navigate("/place-order", { state: { selectedItems } });
      }, 100);
    } catch (error) {
      toast.error("Failed to update cart items");
      // Handle error if needed
    }
  };
  return (
    <div>
      <Header />

      <div className="py-4 min-h-[calc(100vh_-322.8px-_64.625px)] lg:py-6 mb-5 px-4 md:px-10 lg:px-20 flex flex-col lg:flex-row">
        <div className="bg-white p-4 lg:px-10 lg:py-6 flex flex-col gap-6 flex-1">
          <div className="flex items-center justify-between gap-4">
            <h1 className="text-lg font-bold">Shopping Cart</h1>
          </div>
          <div className="flex items-center justify-between gap-4">
            <label className="flex items-center gap-1.5">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAllChange}
              />
              <span>Select All</span>
            </label>

            <button
              className="bg-[#FEF2F2] px-3 py-1 flex items-center gap-1.5 text-[15px] text-red-900 font-medium"
              onClick={() => removeAll()}
            >
              <BiTrashAlt />
              Clear All
            </button>
          </div>

          {productData.map((product, index) => (
            <div
              key={index}
              className="relative px-3 py-3 border border-gray-400 bg-white flex flex-col vsm:flex-row lg:items-center gap-3 md:gap-4 lg:gap-6 justify-between"
            >
              <input
                type="checkbox"
                checked={product.selected}
                onChange={(e) => handleCheckboxChange(e, product.id)}
              />
              <div>
                <img
                  src={`${product.imageUrl}`}
                  alt=""
                  className="w-[150px] h-[100px] md:w-[200px] md:h-[150px] object-cover"
                />
              </div>
              <div className="flex-1 flex flex-col lg:flex-row lg:items-center gap-3 lg:gap-6 justify-between">
                <div className="flex flex-col gap-3 font-medium">
                  <div className="flex flex-col gap-1">
                    <h1 className="text-[17px] font-bold">{product.title}</h1>
                  </div>
                  <div className="text-[15px] text-gray-500 flex flex-col md:flex-row md:items-center gap-2 md:gap-6 xl:gap-8 lg:justify-between">
                    <div className="flex items-center gap-2.5">
                      <p>Brand :</p>
                      <p>{product.brand}</p>
                    </div>
                  </div>
                  <div className="text-[15px] text-gray-500 flex flex-col md:flex-row md:items-center gap-2 md:gap-6 xl:gap-8 lg:justify-between">
                    <div className="flex items-center gap-2.5">
                      <p>Price :</p>
                      <p>{product.singleprice}</p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-6 lg:items-center">
                  <h1 className="text-[17px] font-bold hidden lg:block">
                    Quantity
                  </h1>
                  <div className="flex items-center gap-2">
                    <button
                      className="w-7 h-7 rounded-full text-xl font-bold flex items-center justify-center border-2 border-gray-700 text-gray-700"
                      onClick={() =>
                        handleDecrement(product.productid, product.totalItems)
                      }
                    >
                      -
                    </button>
                    <input
                      type="text"
                      className="max-w-[40px] text-[17px] text-center font-medium"
                      value={product.totalItems}
                      placeholder="0"
                    />
                    <button
                      className="w-7 h-7 rounded-full text-xl font-bold flex items-center justify-center border-2 border-blue text-white bg-blue"
                      onClick={() =>
                        handleIncrement(product.productid, product.totalItems)
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="flex flex-col gap-6 lg:items-center">
                  <h1 className="text-[17px] font-bold hidden lg:block">
                    Price
                  </h1>
                  <p className="font-medium">{product.totalPrices}</p>
                </div>
                <div className="flex flex-col gap-6 absolute top-4 right-4 lg:static lg:items-center">
                  <h1 className="text-[17px] font-bold hidden lg:block">
                    Action
                  </h1>
                  <div>
                    <button
                      className="z-20"
                      onClick={() => handleRemove(product.productid)}
                    >
                      <BiTrashAlt className="text-red-900 text-xl" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-6 lg:mt-0 lg:ml-6 md:w-1/3">
          <div className="rounded-lg  bg-white p-6 shadow-md">
            <div className="mb-2 flex justify-between">
              <p className="text-gray-700">Subtotal</p>
              <p className="text-gray-700">Rs. {totalPrices.toString()}</p>
            </div>
            <div className="flex justify-between">
              <p className="text-gray-700">Shipping fee</p>
              <p className="text-gray-700">Rs. 0</p>
            </div>
            <hr className="my-4" />
            <div className="flex justify-between">
              <p className="text-lg font-bold">Total</p>
              <div>
                <p className="mb-1 text-lg font-bold">
                  Rs. {totalPrices.toString()}
                </p>
              </div>
            </div>
            <button
              className="mt-6 w-full rounded-md bg-yellowlight py-1.5 font-medium text-black hover:bg-yellow"
              onClick={() => navigate("/")}
            >
              Continue Shopping
            </button>
            <button
              className="mt-6 w-full rounded-md bg-blue-500 py-1.5 font-medium text-black hover:bg-blue-700"
              onClick={handleCheckOut}
            >
              Check out
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ShoppingCart;
