import axios from "axios";
import { atom, useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { MdAccountBalance } from "react-icons/md";
import { currentStepAtom } from "../../../components/SellerPage/Signup/StepsIndicator";
import {
  isVendorEditAtom,
  vendorToEditAtom,
} from "../../Admin/VendorsManagement/AcceptedVendors/AcceptedVendorsTableRow";
import FileInputWithLabel from "../../common/FileInputWithLabel";
import InputWithLabel from "../../common/InputWithLabel";
import { useridAtom } from "../../modals/VerificationModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addressIdAtom } from "./AddAddressForm";
import { profileIdAtom } from "./AddProfileForm";
import { useNavigate } from "react-router-dom";

export const bankIdAtom = atom(null);

const VerifyIdAndBank = ({ openSampleModal }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    ownername: "",
    businessregdnumber: "",
    businessdocuments: [null, null, null, null],
    bankdocument: null,
    seller: null,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [vendorToEdit] = useAtom(vendorToEditAtom);
  const [isVendorEdit] = useAtom(isVendorEditAtom);
  const [profileId] = useAtom(profileIdAtom);
  const [addressId] = useAtom(addressIdAtom);

  const isBusinessRegNumberValid = (businessRegNumber) => {
    return /^\d{9}$/.test(businessRegNumber);
  };

  useEffect(() => {
    if (vendorToEdit && vendorToEdit.length > 0) {
      const vendorData = vendorToEdit[0];
      let businessdocuments = [null, null, null, null];
      if (Array.isArray(vendorData.documents.businessdocuments)) {
        const images = vendorData.documents.businessdocuments.map(
          (doc) => doc.image
        );
        businessdocuments = [...images, ...businessdocuments].slice(0, 4);
      }

      setFormData({
        ownername: vendorData.documents.ownername || "",
        businessregdnumber: vendorData.documents.businessregdnumber || "",
        businessdocuments: businessdocuments,
        bankdocument: vendorData.documents.bankdocument || null,
        seller: vendorData.seller || null,
      });
    }
  }, [vendorToEdit]);

  const [bankId, setBankId] = useAtom(bankIdAtom);
  const [userId] = useAtom(useridAtom);

  const [currentStep, setCurrentStep] = useAtom(currentStepAtom);
  const [errors, setErrors] = useState({
    ownername: "",
    businessregdnumber: "",
    businessdocuments: "",
    bankdocument: "",
  });

  const handleFormSubmit = async (e) => {
    console.log("handle submit api is called");
    e.preventDefault();

    const newErrors = validateForm();
    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("ownername", formData.ownername);
      formDataToSend.append("businessregdnumber", formData.businessregdnumber);
      formDataToSend.append("seller", formData.seller);

      formData.businessdocuments.forEach((file, index) => {
        if (file) {
          formDataToSend.append(`businessdocuments[${index}]`, file);
        }
      });

      if (formData.bankdocument) {
        formDataToSend.append("bankdocument", formData.bankdocument);
      }

      const url = process.env.REACT_APP_BASE_URL + "newseller/documents/";
      const response = await axios.post(url, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.status === "success") {
        const responseData = response.data;
        const sellerDocumentId = responseData.id;
        setBankId(sellerDocumentId);

        const formDataToSend = new FormData();
        formDataToSend.append("sellerprofile", profileId);
        formDataToSend.append("selleraddress", addressId);
        formDataToSend.append("documents", sellerDocumentId);
        formDataToSend.append("seller", userId);

        const sendurl = process.env.REACT_APP_BASE_URL + "newseller/seller/";
        const res = await axios.post(sendurl, formDataToSend, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.data.msg === "Seller added Sucessfullly") {
          toast.success(
            "Account created successfully! Please login to continue."
          );
          navigate("/login");
        } else {
          toast.error("Error creating Seller Profile");
        }
      } else {
        console.error("Error creating Seller Profile");
      }
    } catch (error) {
      console.error("Error sending data:", error);
      toast.error("Error adding details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = async (e) => {
    console.log("handle edit api is called ");
    e.preventDefault();

    const newErrors = validateForm();
    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("ownername", formData.ownername);
      formDataToSend.append("businessregdnumber", formData.businessregdnumber);
      formDataToSend.append("seller", formData.seller);

      formData.businessdocuments.forEach((file, index) => {
        if (file) {
          formDataToSend.append(`businessdocuments[${index}]`, file);
        }
      });

      if (formData.bankdocument) {
        formDataToSend.append("bankdocument", formData.bankdocument);
      }

      const url = process.env.REACT_APP_BASE_URL + "newseller/documents/";
      const response = await axios.post(url, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        const responseData = response.data;
        const sellerDocumentId = responseData.id;
        setBankId(sellerDocumentId);

        const formDataToSend = new FormData();
        formDataToSend.append("sellerprofile", profileId);
        formDataToSend.append("selleraddress", addressId);
        formDataToSend.append("documents", sellerDocumentId);
        formDataToSend.append("seller", userId);

        const sendurl = process.env.REACT_APP_BASE_URL + "newseller/seller/";
        const sendDetailsResponse = await axios.post(sendurl, formDataToSend, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (sendDetailsResponse.status === 201) {
          toast.success("Account Updated Successfully.");
          navigate("/admin/vendor-management");
        } else {
          toast.error("Error updating Seller Profile");
        }
      } else {
        console.error("Error creating Seller Profile");
      }
    } catch (error) {
      console.error("Error sending data:", error);
      toast.error("Error adding details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleFileChange = (file, index) => {
    if (index === "bankdocument") {
      setFormData((prevData) => ({
        ...prevData,
        bankdocument: file,
      }));

      setErrors((prevErrors) => ({ ...prevErrors, bankdocument: "" }));
    } else {
      const newDocuments = formData.businessdocuments.map((doc, i) =>
        i === index ? file : doc
      );

      setFormData((prevData) => ({
        ...prevData,
        businessdocuments: newDocuments,
      }));

      setErrors((prevErrors) => ({ ...prevErrors, businessdocuments: "" }));
    }
  };

  const validateForm = () => {
    const { ownername, businessregdnumber, businessdocuments, bankdocument } =
      formData;

    const newErrors = {
      ownername: ownername ? "" : "Owner name is required.",
      businessregdnumber: isBusinessRegNumberValid(businessregdnumber)
        ? ""
        : "Business Registration Number should be 9 digits.",
      businessdocuments: businessdocuments.some((doc) => doc !== null)
        ? ""
        : "Please upload at least one business document.",
      bankdocument: bankdocument ? "" : "Please upload your bank document.",
    };

    return newErrors;
  };

  return (
    <div className="flex-1 bg-white py-4 flex flex-col gap-6">
      a
      <ToastContainer />
      <div className="flex items-center justify-between px-8">
        <div className="flex items-center gap-2 text-blue font-bold">
          <MdAccountBalance className="min-w-[20px] min-h-[20px]" />
          <p>Verify ID & Bank</p>
        </div>
      </div>
      <h1 className="px-8 text-[15px] font-bold text-gray-800">
        VERIFY CORPORATE FILE
      </h1>
      <form className="flex flex-col gap-16" encType="multipart/form-data">
        <div className="flex flex-col gap-4 lg:grid lg:grid-cols-2 lg:gap-6 px-8">
          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="ownername"
              label="Legal Name/Business Owner Name"
              type="text"
              placeholder="Ram lal"
              name="ownername"
              value={formData.ownername}
              onChange={handleInputChange}
            />

            {errors.ownername && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.ownername}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <InputWithLabel
              htmlFor="businessregdnumber"
              label="Business Registration Number"
              type="text"
              placeholder="1589636"
              name="businessregdnumber"
              value={formData.businessregdnumber}
              onChange={handleInputChange}
            />

            {errors.businessregdnumber && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.businessregdnumber}
              </p>
            )}
          </div>

          <div className="w-full lg:col-span-2 flex flex-col items-start gap-1.5 relative">
            <label className="text-[14px] uppercase text-gray-800 font-bold">
              Upload Business Documents (At least one)
            </label>

            <div className="grid gap-3 md:grid-cols-2 xl:grid-cols-4 w-full">
              {formData.businessdocuments.map((document, index) => (
                <div key={index}>
                  <FileInputWithLabel
                    pathName={document}
                    onChange={(file) => handleFileChange(file, index)}
                  />
                </div>
              ))}
            </div>

            {errors.businessdocuments && (
              <p className="text-red-900 text-[14px] font-medium">
                {errors.businessdocuments}
              </p>
            )}
          </div>

          <div className="bg-gray-200 h-[2px] w-full my-3 col-span-2"></div>

          <div className="flex flex-col gap-6 col-span-2">
            <h1 className="text-[15px] font-bold text-gray-800">
              VERIFY BANK INFO
            </h1>

            <div className="relative col-span-2">
              <button
                className="z-10 underline absolute right-0 text-secondary-900 text-[14px] font-medium"
                onClick={openSampleModal}
              >
                View Samples
              </button>

              <div>
                <FileInputWithLabel
                  label="Upload Bank Passbook / Cheque / Statement / Mobile Banking Screenshot"
                  pathName={formData.bankdocument}
                  onChange={(file) => handleFileChange(file, "bankdocument")}
                />

                {errors.bankdocument && (
                  <p className="text-red-900 text-[14px] font-medium">
                    {errors.bankdocument}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="pt-6 px-6 flex flex-row justify-between border-t border-gray-200">
          <button
            type="submit"
            onClick={() =>
              setCurrentStep((prevStep) => Math.max(prevStep - 1, 1))
            }
            className="border border-gray-300 px-6 py-1 rounded-md text-[14px] text-gray-500 font-semibold"
          >
            Back
          </button>

          {isVendorEdit ? (
            <button
              type="submit"
              onClick={handleEdit}
              className="bg-blue px-6 py-1 rounded-md text-[14px] text-white font-semibold"
            >
              {isLoading ? (
                <div role="status" className="flex justify-center items-center">
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 text-gray-300 animate-spin dark:text-gray-600 fill-white"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Edit"
              )}
            </button>
          ) : (
            <button
              type="submit"
              onClick={handleFormSubmit}
              className="bg-blue px-6 py-1 rounded-md text-[14px] text-white font-semibold"
            >
              {isLoading ? (
                <div role="status" className="flex justify-center items-center">
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 text-gray-300 animate-spin dark:text-gray-600 fill-white"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Add"
              )}
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default VerifyIdAndBank;
