import axios from "axios";
import { atom } from "jotai";
import React, { useEffect, useState } from "react";
import { FiSearch, FiFilter } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import CategoryCard from "../common/CategoryCard";
import { Link, useNavigate } from "react-router-dom";

// Atom for selected details
export const selectedDetailsAtom = atom({
  search: null,
});

const SearchModal = ({ searchInput, setSearchInput, setIsSearchModalOpen }) => {
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false); // State to toggle filters dropdown
  const [filters, setFilters] = useState({
    vehicle_company: "",
    brand: "",
    model: "",
    manufacture_year: "",
    part_categories: "",
    part_subcategories: "",
  });

  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  const buildFilterQuery = () => {
    const query = Object.entries(filters)
      .filter(([_, value]) => value) // Filter out empty values
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    return query ? `&${query}` : "";
  };

  // Handle search input change and debounce the API call
  useEffect(() => {
    const fetchData = async () => {
      if (searchInput.trim() === "") {
        setApiData(null);
        return;
      }

      setLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}users/partnumbers/?search=${searchInput}${buildFilterQuery()}`
        );
        setApiData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    // Debounce API call to prevent excessive requests
    const debounceTimeout = setTimeout(() => {
      fetchData();
    }, 300); // 300ms debounce delay

    return () => clearTimeout(debounceTimeout);
  }, [searchInput, filters, apiUrl]);

  useEffect(() => {
    navigate("/"); // Navigate to root URL when modal opens
  }, []);

  const closeModal = () => {
    setIsSearchModalOpen(false);
    setSearchInput("");
  };

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  return (
    <div className="fixed top-0 left-0 w-full h-screen z-[60] overflow-hidden flex bg-black bg-opacity-50 md:items-center md:justify-center md:p-6">
      <div className="pt-10 w-full md:pt-0">
        <div className="relative bg-white h-full rounded-xl p-6 mx-auto flex flex-col gap-4 max-w-[1200px] md:h-[90vh]">
          <div className="flex items-center justify-between gap-4">
            <h1 className="text-2xl text-blue-800 font-bold">Search</h1>
            <button className="text-2xl" onClick={closeModal}>
              <IoClose />
            </button>
          </div>
          <div className="flex justify-between items-center border border-gray-300 bg-[#f9f9fb] py-2 px-3 rounded-lg">
            <div className="flex items-center w-full">
              <input
                type="text"
                placeholder="Search in osis"
                className="outline-none bg-[#f9f9fb] w-full text-[15px] p-2 rounded-lg"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <button className="bg-blue rounded-lg flex items-center justify-center text-white px-4 py-2 ml-2">
                <FiSearch />
              </button>
            </div>
            <button
              className="flex items-center justify-center text-gray-700 px-4 py-2 ml-4 bg-gray-200 rounded-lg"
              onClick={toggleFilters}
            >
              <FiFilter />
              <span className="ml-2">Filters</span>
            </button>
          </div>
          {filtersOpen && (
            <div className="flex flex-wrap gap-4 pt-4 bg-gray-100 p-4 rounded-lg">
              <input
                type="text"
                name="vehicle_company"
                placeholder="Vehicle Company"
                className="flex-1 outline-none border px-4 py-3 rounded-lg"
                value={filters.vehicle_company}
                onChange={handleFilterChange}
              />
              <input
                type="text"
                name="brand"
                placeholder="Brand"
                className="flex-1 outline-none border px-4 py-3 rounded-lg"
                value={filters.brand}
                onChange={handleFilterChange}
              />
              <input
                type="text"
                name="model"
                placeholder="Model"
                className="flex-1 outline-none border px-4 py-3 rounded-lg"
                value={filters.model}
                onChange={handleFilterChange}
              />
              <input
                type="text"
                name="manufacture_year"
                placeholder="Manufacture Year"
                className="flex-1 outline-none border px-4 py-3 rounded-lg"
                value={filters.manufacture_year}
                onChange={handleFilterChange}
              />
              <input
                type="text"
                name="part_categories"
                placeholder="Part Categories"
                className="flex-1 outline-none border px-4 py-3 rounded-lg"
                value={filters.part_categories}
                onChange={handleFilterChange}
              />
              <input
                type="text"
                name="part_subcategories"
                placeholder="Part Subcategories"
                className="flex-1 outline-none border px-4 py-3 rounded-lg"
                value={filters.part_subcategories}
                onChange={handleFilterChange}
              />
            </div>
          )}
          <div className="flex flex-col gap-4 overflow-y-auto">
            <div>
              {apiData && apiData.length > 0 ? (
                <div className="grid vsm:grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
                  {apiData.map((item) => (
                    <Link
                      to={
                        item.is_product
                          ? `productDetail/${item.part.id}`
                          : `partDetail/${item.part.id}`
                      }
                      key={item.part.id}
                    >
                      <CategoryCard
                        key={item.part.id}
                        title={item.part.partSubCategories}
                        icon={
                          item.part
                            ? item.part.image
                            : "https://www.svgrepo.com/show/508699/landscape-placeholder.svg"
                        }
                      />
                    </Link>
                  ))}
                </div>
              ) : (
                <h1 className="text-lg text-center text-gray-500 font-medium">
                  No data found
                </h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
