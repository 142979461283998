import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify"; // Import ToastContainer and toast from react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import CSS for react-toastify
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import DigiLogo from "../../../assets/svg/digi.svg";
import { atom, useAtom } from "jotai";

export const buynowValue = atom(false);
export const indivisualprice = atom(0);
export const indproductDetails = atom(0);

const ProductDetailsTop = () => {
  const [buynow, setBuynow] = useAtom(buynowValue);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [indivisualPrice, setIndivisualPrice] = useAtom(indivisualprice);
  const [indproductdetails, setindProductDetails] = useAtom(indproductDetails);
  const [productDetails, setProductDetails] = useState({
    productImages: [],
    selectedImage: "",
    brand: "",
    title: "",
    rating: "",
    sold: "",
    normalRate: "",
    bulkRate: "",
    guarantee: "",
    details: [],
  });

  const images = [
    "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/e79ff877967627.5c979a697c50b.png",
    "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/e79ff877967627.5c979a697c50b.png",
    "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/e79ff877967627.5c979a697c50b.png",
    "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/e79ff877967627.5c979a697c50b.png",
    "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/e79ff877967627.5c979a697c50b.png",
    "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/e79ff877967627.5c979a697c50b.png",
    "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/e79ff877967627.5c979a697c50b.png",
    "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/e79ff877967627.5c979a697c50b.png",
  ];

  const { "*": searchInput } = useParams();
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const imageurl = process.env.REACT_APP_BASE;

  if (!apiUrl) {
    console.error(
      "REACT_APP_API_URL is not defined in the environment variables."
    );
  }

  const productUrl = apiUrl
    ? `${apiUrl}users/searchedproduct/${searchInput}`
    : null;
  const {
    productImages,
    selectedImage,
    brand,
    title,
    rating,
    sold,
    normalRate,
    bulkRate,
    guarantee,
    details,
  } = productDetails || {};

  useEffect(() => {
    const token = localStorage.getItem("usertoken");

    if (token) {
      setIsLoggedIn(true);
    }

    if (productUrl) {
      fetch(productUrl)
        .then((res) => res.json())
        .then((response) => {
          const data = response.data;
          if (Array.isArray(data)) {
            // Flatten the array of arrays into a single array of products
            const flattenedData = data.reduce(
              (acc, productsArray) => acc.concat(productsArray),
              []
            );
            console.log("Flattened data:", flattenedData);
            const product = flattenedData[0];
            console.log("Product:", product);

            console.log("Product:", product.part_id.attributes);

            const productImages = product.images.map((image) => image.image);
            const selectedImage = product.part_id.image;
            const brand = product.part_id.brand;
            const title = product.part_id.partName;
            const rating = 4.5; // You can set this value based on your logic or if it's available in the original data
            const sold = parseInt(product.stockQuantity); // Assuming stockQuantity is a numeric value
            const normalRate = `Rs ${product.normalRate}`; // Assuming sellingPrice is a numeric value
            const bulkRate = `Rs ${product.bulkRate}`; // Assuming sellingPrice is a numeric value
            const guarantee = "6 months"; // You can set this value based on your logic
            productImages.push(selectedImage);

            const partNumberString = Array.isArray(product.part_id.partnumber)
              ? product.part_id.partnumber
                  .map((part) => part.partNumber)
                  .join(", ")
              : "";
            const details = [
              { label: "Part Number", value: partNumberString },
              { label: "Stock", value: product.stockQuantity },
              { label: "Model", value: product.part_id.model },
              {
                label: "ManufactureYear",
                value: product.part_id.manufactureYear,
              },
              { label: "Category", value: product.part_id.partCategories },
            ];

            setProductDetails(() => ({
              productImages: productImages,
              selectedImage: selectedImage,
              brand: brand,
              title: title,
              rating: rating,
              sold: sold,
              normalRate: normalRate,
              bulkRate: bulkRate,
              guarantee: guarantee,
              details: details,
            }));

            console.log("Transformed data:", productDetails);
          } else {
            console.error("API response is not an array:", data);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [searchInput, productUrl]);

  useEffect(() => {
    const partNumberObject = details.find(
      (detail) => detail.label === "Part Number"
    );
    const partNumber = partNumberObject ? partNumberObject.value : null;

    const productimageurl =
      process.env.REACT_APP_BASE_URL + `users/getpartimage/${partNumber}`;
    if (productimageurl) {
      fetch(productimageurl)
        .then((res) => res.json())
        .then((response) => {
          const data = response;
          console.log(data);
          data.map((img) =>
            setProductDetails((prevState) => ({
              ...prevState,
              productImages: [...prevState.productImages, img],
            }))
          );
          setProductDetails((prevState) => ({
            ...prevState,
            selectedImage: prevState.productImages[2],
          }));
        })
        .catch((error) => {
          console.error("Error fetching images:", error);
        });
    }
  }, [details, setProductDetails]);

  const handleSelectImage = (image) => {
    setProductDetails((prevState) => ({ ...prevState, selectedImage: image }));
  };

  console.log("productDetails", productDetails);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    nextArrow: (
      <div>
        <button className="relative -top-6 bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center text-gray-700 text-2xl focus:outline-none">
          <span className="sr-only">Next</span>
          <span className="font-bold">{" > "}</span>
        </button>
      </div>
    ),
    prevArrow: (
      <div>
        <button className="relative -top-6 bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center text-gray-700 text-2xl focus:outline-none">
          <span className="sr-only">Previous</span>
          <span className="font-bold">{" < "}</span>
        </button>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          arrows: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          arrows: false,
        },
      },
    ],
  };

  // Dynamically adjust slidesToShow based on the number of images
  if (images === 1) {
    sliderSettings.slidesToShow = 1;
  } else if (images.length === 2) {
    sliderSettings.slidesToShow = 2;
  } else if (images.length === 3) {
    sliderSettings.slidesToShow = 3;
  } else if (images.length === 4) {
    sliderSettings.slidesToShow = 4;
  } else if (images.length === 5) {
    sliderSettings.slidesToShow = 5;
  }

  const navigate = useNavigate();

  const handleaddtocart = async () => {
    const token = localStorage.getItem("usertoken");
    const username = localStorage.getItem("user");

    if (!token) {
      // Handle the case where the token is not present (e.g., redirect to login page)
      toast.error("Authentication credentials not provided. Please log in.");
      return;
    }

    // Check if the authentication token has expired (example for JWT, may vary based on implementation)
    const isTokenExpired = () => {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        console.log("decodedToken", decodedToken.exp);
        console.log("currentTime", currentTime);
        console.log("isTokenExpired", decodedToken.exp < currentTime);
        return decodedToken.exp < currentTime;
      } catch (error) {
        return true; // Error decoding token or invalid token, treat as expired
      }
    };

    if (isTokenExpired()) {
      // Handle token expiration (e.g., refresh the token or redirect to login page)
      toast.error("Authentication token has expired. Please log in again.");
      navigate("/login");
      return;
    }

    const url =
      process.env.REACT_APP_BASE_URL + `users/add_to_cart/${searchInput}/`;
    const response = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log("response", response);
    if (response.status === 201) {
      toast.success("Product added to cart successfully");
    } else {
      toast.error("Error adding product to cart");
    }
  };

  const handlechange = async (id) => {
    const token = localStorage.getItem("usertoken");
    const updateInactiveURL =
      process.env.REACT_APP_BASE_URL + "users/update_cart_items/";
    // In Product Data i have id find the roductid thatmatches with this id and set that in activeItemIds
    const activeItemIds = [id]; // Corrected variable name

    try {
      await axios.post(
        updateInactiveURL,
        { item_ids: activeItemIds },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      toast.error("Failed to update cart items");
      // Handle error if needed
    }
  };

  const handleaddtobuy = async () => {
    const token = localStorage.getItem("usertoken");
    const username = localStorage.getItem("user");

    if (!token) {
      // Handle the case where the token is not present (e.g., redirect to login page)
      toast.error("Authentication credentials not provided. Please log in.");
      return;
    }

    // Check if the authentication token has expired (example for JWT, may vary based on implementation)
    const isTokenExpired = () => {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert to seconds
        console.log("decodedToken", decodedToken.exp);
        console.log("currentTime", currentTime);
        console.log("isTokenExpired", decodedToken.exp < currentTime);
        return decodedToken.exp < currentTime;
      } catch (error) {
        return true; // Error decoding token or invalid token, treat as expired
      }
    };

    if (isTokenExpired()) {
      // Handle token expiration (e.g., refresh the token or redirect to login page)
      toast.error("Authentication token has expired. Please log in again.");
      navigate("/login");
      return;
    }

    setBuynow(true);
    setIndivisualPrice(normalRate);

    const url =
      process.env.REACT_APP_BASE_URL + `users/buy-now/${searchInput}/`;
    const response = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log("response", response);
    if (response.status === 201) {
      toast.success("Product Placing Order successfully");
      setBuynow(true);
      console.log("ProductData", response.data.data);
      handlechange(response.data.data);
      setindProductDetails(response.data.data);
      navigate("/cart");
    } else {
      toast.error("Error adding product to cart");
    }
  };

  return (
    <div className="pt-5 md:pt-10 flex flex-col gap-2 md:shadow-lg">
      {/* <div className="hidden md:block">
        <p className="text-white text-[15px]">
          Home - Maintenance Parts - Belt
        </p>
      </div> */}

      <div className="p-4 bg-white">
        <div className="flex gap-2 md:gap-4 flex-col md:flex-row">
          <div className="relative md:w-[60%] flex flex-col gap-6">
            <div className="border-2 border-gray-300">
              <img
                src={
                  selectedImage
                    ? `${selectedImage}`
                    : "https://th.bing.com/th/id/R.f145fbfe280056043be63570724ac0c4?rik=E7e2uVHaKcWmdw&pid=ImgRaw&r=0"
                }
                alt="Product Image"
                className="w-full object-cover max-h-[550px]"
              />
            </div>

            {productImages.length > 1 && (
              <div className="mx-2 md:mx-6">
                <Slider {...sliderSettings}>
                  {productImages.map((image, index) => (
                    <div
                      key={index}
                      onClick={() => handleSelectImage(image)}
                      className="px-1"
                    >
                      <img
                        src={`${image}`}
                        alt="https://th.bing.com/th/id/OIP.uEliYOB4Vy1H-AOZKbeGXwAAAA?rs=1&pid=ImgDetMain"
                        className="w-200px h-32 object-cover cursor-pointer border-2 border-gray-300 "
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </div>

          <div className="md:w-[40%] ">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <p className="text-yellow uppercase text-[15px] font-medium">
                  {brand}
                </p>

                <h1 className="font-bold text-2xl">{title}</h1>

                <div className="flex items-center gap-2">
                  <div className="text-[15px] text-gray-700 font-medium flex gap-1 items-center">
                    <FaStar className="text-yellow" />
                    {rating}
                  </div>

                  <div className="w-[1px] h-[15px] bg-gray-500"></div>

                  <div className="text-[15px]  font-medium text-gray-500">
                    {sold} Product Sold
                  </div>
                </div>

                <p className="text-xl text-blue font-bold mt-2">{normalRate}</p>
                {/* <p className="text-xl text-blue font-bold mt-2">{bulkRate} ( 5+ Items ) </p> */}
              </div>

              <div className="w-full bg-gray-300 h-[2px]"></div>

              <div>
                <ul className="flex flex-col gap-3">
                  {details.map((detail, index) => (
                    <li
                      key={index}
                      className="font-medium text-gray-700 grid grid-cols-2 gap-2"
                    >
                      {detail.label}
                      {detail.label === "Part Number" && !isLoggedIn ? (
                        <span className="font-bold text-gray-800 text-[15px]">
                          ******
                        </span>
                      ) : (
                        <span className="font-bold text-gray-800 text-[15px]">
                          {detail.value}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="flex flex-col gap-4 mt-4">
              <button
                className="w-full bg-yellow py-2.5 rounded-[4px] text-white font-bold px-4"
                onClick={handleaddtobuy}
              >
                Buy Now
              </button>
              <button
                className="w-full bg-blue py-2.5 rounded-[4px] text-white font-bold px-4"
                onClick={handleaddtocart}
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>

        <div className="bg-[#d5e4fe9c] w-full flex flex-col md:flex-row mt-6">
          {/* <div className="w-full md:w-fit flex items-center justify-between md:justify-start gap-5 md:gap-10 px-4 md:px-8 py-2.5 md:py-5 border-b-2 md:border-r-2 border-[#D5E4FE]">
            <div className="flex items-center gap-2">
              <img src={DigiLogo} alt="" className="max-w-[50px]" />
              <div>
                <h1 className="font-bold text-gray-800 text-[15px]">
                  Digi Computers
                </h1>
                <p className="font-medium text-gray-500 text-sm">
                  Osis Verified
                </p>
              </div>
            </div>
          </div> */}

          <div className="md:flex-1 flex flex-col md:flex-row md:items-center justify-between md:gap-4 px-4 md:px-8 py-2.5 md:py-5 border-b-2 md:border-r-2 border-[#D5E4FE]">
            <div className="flex items-center gap-2 py-2.5 md:py-0">
              <img src={DigiLogo} alt="" className="max-w-[50px]" />
              <div>
                <h1 className="font-bold text-gray-800 text-[15px]">
                  100% Authentic from trusted brand
                </h1>
                <p className="font-medium text-gray-500 text-sm">
                  People's choice
                </p>
              </div>
            </div>

            <div className="flex items-center gap-2 py-2.5 md:py-0">
              <img src={DigiLogo} alt="" className="max-w-[50px]" />
              <div>
                <h1 className="font-bold text-gray-800 text-[15px]">
                  Support 24/7
                </h1>
                <p className="font-medium text-gray-500 text-sm">
                  call us anytime for our service
                </p>
              </div>
                        
            </div>

            <div className="flex items-center gap-2 py-2.5 md:py-0">
              <img src={DigiLogo} alt="" className="max-w-[50px]" />
              <div>
                <h1 className="font-bold text-gray-800 text-[15px]">
                  10 Days Assured Return
                </h1>
                <p className="font-medium text-gray-500 text-sm">
                  Return policy
                </p>
              </div>
            </div>

            <div className="flex items-center gap-2 py-2.5 md:py-0">
              <img src={DigiLogo} alt="" className="max-w-[50px]" />
              <div>
                <h1 className="font-bold text-gray-800 text-[15px]">
                  GST Invoice available
                </h1>
                <p className="font-medium text-gray-500 text-sm">
                  Osis Verified
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsTop;
