import { atom, useAtom } from "jotai";
import React from "react";
import "../../../../../styles/table.css";
import AllProductsTableRow from "./AllProductTableRow";
import { useEffect } from "react";

export const rowsPerPageAtom = atom(10);
export const isProductEditModeAtom = atom(false);
export const productIdAtom = atom(null);
export const dataAtom = atom([]);
export const localSelectionAtom = atom([]);

const AllProductsTable = ({
  startIndex,
  endIndex,
  searchQuery,
  fetchData,
  isAnyRowSelected,
  setIsAnyRowSelected,
  selectedRows,
  setSelectedRows,
}) => {
  const [data, setData] = useAtom(dataAtom);
  const [, setIsEditButtonClicked] = useAtom(isProductEditModeAtom);
  const [, setProductId] = useAtom(productIdAtom);
  const [localSelection, setLocalSelection] = useAtom(localSelectionAtom);

  useEffect(() => {
    // Filter data based on the search query and update the data state
    const filteredData = data.filter((item) =>
      Object.values(item).some((value) =>
        value
          ? value.toString().toLowerCase().includes(searchQuery.toLowerCase())
          : false
      )
    );
    setData(filteredData);
  }, [searchQuery]); // Update when searchQuery changes

  const handleCheckboxChange = (index) => {
    const newLocalSelection = [...localSelection];
    newLocalSelection[index] = !newLocalSelection[index];

    const updatedSelectedRows = data.filter((_, i) => newLocalSelection[i]);
    setSelectedRows(updatedSelectedRows);
    setIsAnyRowSelected(updatedSelectedRows.length > 0);

    setLocalSelection(newLocalSelection);
  };

  const handleHeaderCheckboxChange = () => {
    const allSelected = !isAnyRowSelected;
    const newLocalSelection = Array(data.length).fill(allSelected);

    setSelectedRows(allSelected ? [...data] : []);
    setIsAnyRowSelected(allSelected);
    setLocalSelection(newLocalSelection);
  };

  console.log("data", data);

  return (
    <div className="relative bg-white border-2 border-gray-400 overflow-x-auto whitespace-nowrap table-container">
      <table className="w-full text-white text-center border-spacing-4">
        <div className="w-full  border-gray-400 bg-gray-300 text-gray-900 text-[14px] font-bold px-5 py-3 uppercase sm:hidden">
          <h1>All Product Detail</h1>
        </div>

        <thead className="text-gray-900 border-y-2 border-gray-400 uppercase hidden sm:table-header-group">
          <tr>
            <th className="flex justify-center text-center items-center">
              <input
                type="checkbox"
                name=""
                id=""
                onChange={handleHeaderCheckboxChange}
              />
            </th>
            <th className="text-center">Vehicle Company</th>
            <th className="text-center">Sub-Category</th>
            <th className="text-center">Availability</th>
            <th className="text-center">Part Number</th>
            <th className="text-center">Model</th>
            <th className="text-center">Year of Manufacturing</th>
            <th className="text-center">Parts Categories</th>
            <th className="text-center">Sub-Parts Categories</th>
            <th className="text-center">Parts Names</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>

        {data.length !== 0 && (
          <tbody className="text-gray-700">
            {data.map((item, index) => (
              <AllProductsTableRow
                key={index}
                formData={item}
                onAcceptClick={() => {
                  console.log("Accept button clicked");
                }}
                onDeclineClick={() => {
                  console.log("Decline button clicked");
                }}
                onEditClick={() => {
                  console.log("Edit button clicked");
                  setIsEditButtonClicked(true);
                  setProductId(index);
                }}
                onDeleteClick={() => {
                  console.log("Delete button clicked");

                  // Delete product from the database with productId
                }}
                fetchData={fetchData}
                isSelected={localSelection[index]}
                onCheckboxChange={() => handleCheckboxChange(index)}
              />
            ))}
          </tbody>
        )}
      </table>

      {data.length === 0 && (
        <div className="w-full">
          <div className="border-b-2 border-gray-300 text-center h-[65px] flex items-center justify-center text-gray-700 relative hover:bg-gray-100">
            <p className="text-gray-700 font-semibold">No data available</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllProductsTable;
